/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

/* You can add global styles to this file, and also import other style files */
@import '~dropzone/dist/min/dropzone.min.css';

.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5)
  }

  100% {
    transform: scale(1)
  }
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}

ngx-dropzone {
  border: 2px dashed var(--bs-border-color) !important;
}




//
// Dropzone
//

.dropzone {
  min-height: 230px !important;
  border: 2px dashed #eff2f7 !important;
  background: #fff !important;
  border-radius: 6px !important;
  text-align: center;

  .dz-message {
    width: 100%;
    margin: 1em 0;
  }
}

[data-dz-thumbnail] {
  width: 100%;
  height: 100%;
}

.dropzone .dz-preview .dz-image {
  border-radius: 20px;
  overflow: hidden;
  width: 120px;
  height: 120px;
  position: relative;
  display: block;
  z-index: 10;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropzone .dz-preview .dz-image img {
  display: block;
  height: 90px;
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropzone .dz-preview .dz-remove {
  margin-top: 10px;
}

dropzone>.dropzone.dz-wrapper .dz-message {
  background-color: transparent !important;
  border: none !important;
  overflow: inherit !important;
  width: inherit !important;
  min-width: inherit !important;
  min-height: 180px !important;
  max-width: inherit !important;
  max-height: inherit !important;
  margin: 0px !important;
}

.dz-text {
  font-size: 1.21875rem
}

dropzone>.dropzone.dz-wrapper {
  min-height: 76px !important;
  border: none !important;
}


#component-colorpicker {

  color-picker {
    .open {
      right: 0px !important;
      left: auto !important;
      z-index: 9999 !important;
    }
  }
}

.color-picker {
  .arrow.arrow-bottom {
    right: 20px !important;
    left: auto !important;
  }
}

.switch.switch-small>.switch-pane>span {
  font-size: 12px !important;
}

.modal-dialog {
  top: auto !important;
  bottom: auto !important;
}

.slick-dots {
  margin-top: 20px;
  bottom: -40px !important;


  li.slick-active button:before {
    color: #556ee6 !important;
    font-size: x-small !important;
  }
}

.slick-dots li button:before {
  color: #556ee6;
  font-size: x-small !important;
}

.slick-slide img {
  width: 100%;
}

.dropup {
  .dropdown-menu {
    inset: auto auto auto 0px !important;
    transform: translateY(-160px) !important;
  }
}

.ngx-slider .ngx-slider-tick.ngx-slider-selected {
  background-color: #556ee6 !important;
}

/* Dark Mode */
[data-bs-theme="dark"] .form-check-input {
  background-color: #444; /* Darker background for better visibility */
  border-color: #888;     /* Lighter border to contrast with the background */
  color: #fff;            /* White checkmark for visibility */
}

[data-bs-theme="dark"] .form-check-input:checked {
  background-color: #65819D; /* Blue background for checked state */
  border-color: #65819D;     /* Border matches background for a unified look */
  color: #fff;               /* White checkmark for checked state */
}

[data-bs-theme="dark"] .btn-upload-b-color{
  color: #fff !important;               /* White checkmark for checked state */

}
[data-bs-theme="dark"] .shared-me-btn{
  background-color: transparent !important;     
  border-right: none;      

}
[data-bs-theme="dark"] .home-btn{
  background-color: transparent !important;     
  border-right: none;      

}
[data-bs-theme="dark"] .transparent-bg{
  background-color: transparent !important;     
  border-right: none;      

}
[data-bs-theme="dark"] .delete-icon{
  color: var(--bs-secondary-color) !important;
}


[data-bs-theme="dark"] .ng2-tag-input__text-input{
  // color: black !important;
  background-color: transparent !important;
}
/* Light Mode (default) */
.form-check-input {
  background-color: #f8f9fa; /* Light grey background */
  border-color: #2F323E;     /* Border with sufficient contrast */
  color: #495057;            /* Dark grey checkmark for visibility */
}

.form-check-input:checked {
  background-color: #65819D; /* Blue background for checked state */
  border-color: #65819D;     /* Border matches background for a unified look */
  color: #fff;               /* White checkmark for checked state */
}

.ellipsis-text {
  width: 100%;               /* Takes up the full width of the container */
  max-width: 100% !important;           /* Ensures it doesn't overflow the container */
  white-space: nowrap;       /* Prevents the text from wrapping to a new line */
  overflow: hidden;          /* Hides the overflowed text */
  text-overflow: ellipsis;   /* Adds the ellipsis (...) at the end of the truncated text */
  box-sizing: border-box;
  display: block !important;    /* Ensures padding and borders are included in the width calculation */
}

/* Hide default scrollbar */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  /* Adjust width to make the scrollbar slimmer */
}

/* Track */
::-webkit-scrollbar-track {
  background: #CACCBC;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5E6574;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8F9C9B;
}

.active-btn{
  // background-color: #506285 !important;
  font-weight: bold;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color))) !important;
}

.invalid-feedback {
  color: #d00e0e;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}